import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'

import { Microdata, getMetatagContent, getLogo, getImageUrl } from '../Microdata'
import { DrupalTemplate, DrupalNode, DrupalProcessedText, DrupalMediaImage, DrupalBanner } from '../drupal'
import { media, widths } from '../style/variables'
import Layout from '../layouts'
import Container from '../components/Container'
import Stack from '../components/Stack'
import Banner from '../components/Banner'
import Media from '../components/fields/Media'
import ParticipateCard from '../components/ParticipateCard'

export interface ArticleNode extends DrupalNode {
	body: DrupalProcessedText
	created: string
	relationships: {
		field_media: DrupalMediaImage
		field_banner?: DrupalBanner
	}
}

const StyledContainer = styled(Container)`
	/** Make content more readable by limiting max-width. */
	article {
		max-width: ${widths.md}px;
		margin: 0 auto;
		justify-content: center;
		align-items: center;
	}
	article p img {
		width: 100%;
		height: auto;
	}
	article h2 {
		${media.down('xs')} {
			font-size: 1.2rem;
			margin-bottom: 0.8rem;
		}
	}
`
const Wrapper = styled.div`
	margin: 40px 0;
	${media.down('xs')} {
		margin: 30px 0;
	}
`
const ImageWrapper = styled.div`
	margin-bottom: 40px;
	${media.down('xs')} {
		margin-bottom: 20px;
	}
`
const StyledH1 = styled.h1`
	margin-bottom: 1.2rem;
	${media.down('xs')} {
		margin-bottom: 1rem;
		font-size: 1.6rem;
	}
`

function makeArticleMicrodata(node: ArticleNode): Microdata {
	const siteName = getMetatagContent(node.metatag_normalized, 'property', 'og:site_name')

	return [
		{
			'@context': 'http://schema.org',
			'@type': 'Article',
			name: getMetatagContent(node.metatag_normalized, 'name', 'title'),
			image: getImageUrl(node.relationships.field_media),
			description: getMetatagContent(node.metatag_normalized, 'name', 'description'),
			author: {
				'@type': 'Organization',
				name: siteName
			},
			datePublished: node.created,
			headline: node.title,
			publisher: {
				'@type': 'Organization',
				name: siteName,
				logo: {
					'@type': 'ImageObject',
					url: getLogo()
				}
			}
		}
	]
}

const ArticleTemplate: DrupalTemplate<ArticleNode> = ({ data: { node } }) => (
	<Layout node={node} microdata={makeArticleMicrodata(node)}>
		<main>
			<Stack>
				<Banner image={node.relationships.field_banner} />
				<StyledContainer>
					<article>
						<ImageWrapper>
							<Media source={node.relationships.field_media} />
						</ImageWrapper>
						<StyledH1>{node.title}</StyledH1>
						<div dangerouslySetInnerHTML={{ __html: node.body.processed }} />
						{process.env.GATSBY_LANGUAGE === 'fi' && (
							<Wrapper>
								<ParticipateCard href="https://vegaanihaaste.fi/" />
							</Wrapper>
						)}
					</article>
				</StyledContainer>
			</Stack>
		</main>
	</Layout>
)

export default ArticleTemplate

export const pageQuery = graphql`
	query ArticleTemplateQuery($drupalId: String!) {
		node: nodeArticle(drupal_id: { eq: $drupalId }) {
			langcode
			title
			created
			metatag_normalized {
				tag
				attributes {
					name
					content
					property
					href
					rel
				}
			}
			body {
				processed
			}
			relationships {
				field_media {
					__typename
					...MediaImageDefault
				}
				field_banner {
					...DrupalBanner
				}
			}
		}
	}
`
